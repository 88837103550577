import { Link } from "react-router-dom";
import { OrderContext } from "./providers/OrderProvider";
import { useContext, useMemo } from "react";
import ShoppingCartWithCounter from "./shared/ShoppingCartWithCounter";

const Navbar = ({ onLogoClick, step }) => {
  const { order, setOpenShoppingCart } = useContext(OrderContext);
  const hasBasketProducts = order.basket.length > 0;
  const paymentMethodSelected = useMemo(() => {
    return (
      order &&
      order.paymentMethod &&
      (order.paymentMethod === "stripe" ||
        order.paymentMethod === "bankTransfer")
    );
  }, [order]);

  const openShoppingCart = () => {
    if (!order.subTotal > 0) {
      return;
    }
    setOpenShoppingCart(true);
  };

  return (
    <nav className="self-stretch flex flex-row py-[1.5rem] px-[2rem] items-center justify-between text-left text-[1rem] text-dimgray font-graphie md:flex-row md:gap-[1.5rem] md:items-center md:justify-between md:pl-[1.5rem] md:pr-[1.5rem] md:box-border md:flex-wrap sm:self-stretch sm:w-auto sm:gap-[1.5rem] sm:items-center sm:justify-between">
      <img
        className="relative w-[9.19rem] h-[3rem] overflow-hidden shrink-0 cursor-pointer md:w-[7.63rem] md:h-[2.5rem] md:cursor-pointer sm:w-24 sm:h-auto"
        alt=""
        src="/hah1-11.svg"
        onClick={onLogoClick}
      />
      <div className="flex-1 rounded-3xl bg-color-grey-100 flex flex-row py-[0.75rem] px-[2.5rem] box-border items-center justify-center gap-[1rem] max-w-[40rem] md:flex-1 md:h-auto md:items-center md:justify-center md:min-w-full md:order-[3] sm:flex-row sm:gap-[0.5rem] sm:items-center sm:justify-between sm:py-[0.5rem] sm:px-[1rem] sm:box-border sm:order-[3]">
        <Link
          className={`${
            step < 1 || order.status == "complete"
              ? "pointer-events-none cursor-auto"
              : ""
          } cursor-pointer [text-decoration:none] relative leading-[150%] text-[inherit] sm:text-[0.75rem]`}
          to="/"
        >
          <div
            className={`${
              step >= 1
                ? "font-semibold text-black opacity-100"
                : "opacity-[0.5]"
            } flex flex-row items-start justify-start gap-[0.25rem] text-center sm:flex-col sm:gap-[0rem] sm:items-center sm:justify-start`}
          >
            1.
            <div className="relative leading-[150%] sm:text-[0.75rem] sm:text-center">
              Výběr
            </div>
          </div>
        </Link>
        <div className="flex-1 flex flex-col items-start justify-start min-w-[1.5rem] sm:hidden">
          <div className="self-stretch relative box-border h-[0.06rem] border-t-[1px] border-solid border-color-grey-300" />
          <div className="self-stretch relative box-border h-[0.06rem] opacity-[0] mt-[-0.06rem] border-t-[1px] border-solid border-color-black" />
        </div>
        <Link
          className={`${
            (step < 2 && !hasBasketProducts) || order.status == "complete"
              ? "pointer-events-none cursor-auto"
              : ""
          } cursor-pointer [text-decoration:none] relative leading-[150%] text-[inherit] sm:text-[0.75rem]`}
          to="/payment-choice"
        >
          <div
            className={`${
              step >= 2
                ? "font-semibold text-black opacity-100"
                : "opacity-[0.5]"
            } flex flex-row items-start justify-start gap-[0.25rem] text-center sm:flex-col sm:gap-[0rem] sm:items-center sm:justify-start`}
          >
            <div className="relative leading-[150%] sm:text-[0.75rem]">2.</div>
            <div className="relative leading-[150%] sm:text-[0.75rem] sm:text-center">
              Platba
            </div>
          </div>
        </Link>
        <div className="flex-1 flex flex-col items-start justify-start min-w-[1.5rem] sm:hidden sm:flex-1 sm:min-w-[1rem]">
          <div className="self-stretch relative box-border h-[0.06rem] border-t-[1px] border-solid border-color-grey-300" />
          <div className="self-stretch relative box-border h-[0.06rem] opacity-[0] mt-[-0.06rem] border-t-[1px] border-solid border-color-black" />
        </div>

        <Link
          className={`${
            (step < 3 && !hasBasketProducts) ||
            !paymentMethodSelected ||
            order.status == "complete"
              ? "pointer-events-none cursor-auto"
              : ""
          } cursor-pointer [text-decoration:none] relative leading-[150%] text-[inherit] sm:text-[0.75rem]`}
          to="/delivery-choice"
        >
          <div
            className={`${
              step >= 3
                ? "font-semibold text-black opacity-100"
                : "opacity-[0.5]"
            } flex flex-row items-start justify-start gap-[0.25rem] text-center sm:flex-col sm:gap-[0rem] sm:items-center sm:justify-start`}
          >
            <div className="relative leading-[150%] sm:text-[0.75rem]">3.</div>
            <div className="relative leading-[150%] sm:text-[0.75rem] sm:text-center">
              Doručovací údaje
            </div>
          </div>
        </Link>
        <div className="flex-1 flex flex-col items-start justify-start min-w-[1.5rem] sm:hidden sm:flex-1 sm:min-w-[1rem]">
          <div className="self-stretch relative box-border h-[0.06rem] border-t-[1px] border-solid border-color-grey-300" />
          <div className="self-stretch relative box-border h-[0.06rem] opacity-[0] mt-[-0.06rem] border-t-[1px] border-solid border-color-black" />
        </div>
        <div
          className={`${
            step >= 4 ? "font-semibold text-black opacity-100" : "opacity-[0.5]"
          } flex flex-row items-start justify-start gap-[0.25rem] text-center sm:flex-col sm:gap-[0rem] sm:items-center sm:justify-start`}
        >
          <div className="relative leading-[150%] sm:text-[0.75rem]">4.</div>
          <div className="relative leading-[150%] sm:text-[0.75rem] sm:text-center">
            Hotovo
          </div>
        </div>
      </div>
      <button
        onClick={openShoppingCart}
        className={`${
          step === 4
            ? "pointer-events-none cursor-auto"
            : "hover:bg-blanchedalmond cursor-pointer"
        }  no-underline [border:none] py-[0.75rem] pr-[2.5rem] pl-[2rem] bg-color-beige rounded-full overflow-hidden flex flex-row items-center justify-start gap-[0.75rem] md:w-auto md:h-auto md:py-[0.5rem] md:pr-[2rem] md:pl-[1.5rem] md:box-border md:order-[2] sm:w-auto sm:h-auto sm:py-[0.5rem] sm:pr-[2rem] sm:pl-[1.5rem] sm:box-border sm:order-[2]`}
      >
        {step < 4 && (
          <>
            <ShoppingCartWithCounter productsCount={order.basket.length} />
            <b className="flex-1 relative text-[1rem] font-graphie text-color-black text-left md:text-[0.88rem] sm:text-[0.88rem]">
              {!hasBasketProducts ? "Košík je prázdný" : `${order.subTotal} Kč`}
            </b>
          </>
        )}
        {step == 4 && (
          <>
            <img
              className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 md:w-[1.25rem] md:h-[1.25rem] sm:w-[1.25rem] sm:h-[1.25rem]"
              alt=""
              src="/check.svg"
            />
            <b className="flex-1 relative text-[1rem] font-graphie text-color-black text-left md:text-[0.88rem] sm:text-[0.88rem]">
              Zaplaceno
            </b>
          </>
        )}
      </button>
    </nav>
  );
};

export default Navbar;
