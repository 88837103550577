import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import ProductCategories from "../components/ProductCategories";
import { AnimatePresence, motion } from "framer-motion";

const CategoriesTabs = [
  {
    id: "colors",
    heading: "Vyber si barvu",
    description: "99.9% krytí šedin",
    route: "/colors",
    tabName: "Přírodní odstíny",
  },
  {
    id: "tones",
    heading: "Vyber si barvu",
    description: "Pro výraznější krytí smíchej s přírodním odstínem 1:1.",
    route: "/tones",
    tabName: "Barevné odstíny",
  },
  {
    id: "masks",
    heading: "Vyber si masku",
    description: "Perfektní rozčesávání, hydratace a obnova tvých vlasů.",
    route: "/masks",
    tabName: "Masky",
  },
];

const Categories = ({ children }) => {
  const [activeTab, setActiveTab] = useState(CategoriesTabs[0]);
  const location = useLocation();

  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    setActiveTab(CategoriesTabs.find((x) => x.route === location.pathname));
  }, [location]);

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-start">
      <Navbar onLogoClick={onLogoClick} step={1} />
      <main className="self-stretch flex flex-col pt-[2rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[2.5rem] sm:pl-[1.5rem] sm:pr-[1.5rem] sm:box-border">
        <header className="w-full self-stretch bg-color-white flex flex-col items-center justify-start gap-[1.5rem] max-w-[50rem] my-0 mx-auto text-center text-[1.5rem] text-color-black font-ivy-mode sm:gap-[1.5rem]">
          <h1 className="m-0 self-stretch relative text-[inherit] leading-[150%] font-bold font-inherit md:text-[1.5rem] sm:text-[1.5rem] sm:text-color-black">
            {activeTab.heading}
          </h1>
          <ProductCategories tabs={CategoriesTabs} />
          <div className="relative text-[1.25rem] leading-[150%] font-graphie text-dimgray md:text-[1.13rem]">
            {activeTab.description}
          </div>
        </header>
        <AnimatePresence mode="wait">
          <motion.div
            key={location.pathname}
            initial={{
              opacity: 0,
              x: "100%",
            }}
            animate={{ opacity: 1, x: "0%" }}
            exit={{
              opacity: 0,
              x: "-100%",
            }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-1 justify-center gap-8"
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </main>
    </div>
  );
};

export default Categories;
