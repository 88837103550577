import { useCallback, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import ArrowLeft from "../../svg/ArrowLeft";

const NavigateBackButton = ({ productCategory }) => {
  const navigate = useNavigate();

  const navigateBackLink = useMemo(() => {
    switch (productCategory) {
      case "Krycí barvy":
        return "/colors";
      case "Odstíny":
        return "/tones";
      case "Masky":
        return "/masks";
      default:
        return undefined;
    }
  }, [productCategory]);

  const onNavigateBack = useCallback(() => {
    navigate(navigateBackLink || -1);
  }, [productCategory]);

  return (
    <Link
      className="pt-2 cursor-pointer no-underline bg-transparent flex gap-2 items-center font-graphie text-color-black text-base leading-6 group hover:text-brown focus:text-brown focus:border-b focus:border-b-brown outline-none"
      to={navigateBackLink}
      onClick={(e) => {
        e.preventDefault();
        onNavigateBack();
      }}
    >
      <ArrowLeft
        className="group-hover:stroke-brown group-focus:stroke-brown stroke-color-black w-6"
        strokeWidth={2}
      />
      Zpět
    </Link>
  );
};

export default NavigateBackButton;
