function timeDifference(date1, date2) {
  var difference = date2.getTime() - date1.getTime();

  var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

  return daysDifference;

  /*difference -= daysDifference * 1000 * 60 * 60 * 24;

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    var secondsDifference = Math.floor(difference / 1000);

    console.log(
      "difference = " +
        daysDifference +
        " day/s " +
        hoursDifference +
        " hour/s " +
        minutesDifference +
        " minute/s " +
        secondsDifference +
        " second/s "
    );*/
}

export default timeDifference;