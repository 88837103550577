import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { OrderContext } from "../providers/OrderProvider";
import NavigateBackButton from "./NavigateBackButton";
import ShoppingCartWithCounter from "../shared/ShoppingCartWithCounter";

const DetailsLayout = ({ children, productInfo }) => {
  const { order, setOpenShoppingCart } = useContext(OrderContext);
  const hasBasketProducts = order.basket.length > 0;

  const openShoppingCart = () => {
    if (!order.subTotal > 0) {
      return;
    }
    setOpenShoppingCart(true);
  };

  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-start">
      <nav className="self-stretch flex flex-row py-[1.5rem] px-[2rem] items-center justify-between text-left text-[1rem] text-dimgray font-graphie gap-3 md:flex-row md:gap-[1.5rem] md:items-center md:justify-between md:pl-[1.5rem] md:pr-[1.5rem] md:box-border sm:self-stretch sm:w-auto sm:gap-[1.5rem] sm:items-center sm:justify-between">
        <img
          className="relative sm2:hidden w-[9.19rem] h-[3rem] overflow-hidden shrink-0 cursor-pointer md:w-[7.63rem] md:h-[2.5rem] md:cursor-pointer sm:w-24 sm:h-auto"
          alt=""
          src="/hah1-11.svg"
          onClick={onLogoClick}
        />
        <div className="max-w-[47.75rem] w-full mx-auto">
          <NavigateBackButton
            productCategory={productInfo ? productInfo.category : ""}
          />
        </div>
        <button
          onClick={openShoppingCart}
          className={` whitespace-nowrap cursor-pointer hover:bg-blanchedalmondno-underline [border:none] py-[0.75rem] pr-[2.5rem] pl-[2rem] bg-color-beige rounded-full grid grid-cols-[auto_1fr] items-center justify-start gap-[0.75rem] md:w-auto md:h-auto md:py-[0.5rem] md:pr-[2rem] md:pl-[1.5rem] md:box-border md:order-[2] sm:w-auto sm:h-auto sm:py-[0.5rem] sm:pr-[2rem] sm:pl-[1.5rem] sm:box-border sm:order-[2]`}
        >
          <ShoppingCartWithCounter productsCount={order.basket.length} />
          <b className="relative text-[1rem] font-graphie text-color-black text-left md:text-[0.88rem] sm:text-[0.88rem]">
            {!hasBasketProducts ? "Košík je prázdný" : `${order.subTotal} Kč`}
          </b>
        </button>
      </nav>
      {children}
    </div>
  );
};

export default DetailsLayout;
