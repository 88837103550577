import CounterInput from "../../components/shared/CounterInput";
import formatPrice from "../../utils/functions/formatPrice";
import { Link, useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import { useContext, useCallback, useMemo } from "react";
import { OrderContext } from "../../components/providers/OrderProvider";
import { LockBodyScroll } from "./ShoppingCart";
import ProductSizeSelector from "../shared/ProductSizeSelector";

const dropIn = {
  hidden: {
    top: "40%",
    opacity: 0,
  },
  visible: {
    top: "50%",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    top: "40%",
    opacity: 0,
  },
};

const DesktopCart = ({ changeUnitCount, removeFromBasket }) => {
  const { setOpenShoppingCart, order } = useContext(OrderContext);

  const navigate = useNavigate();

  const onCompleteCart = useCallback(() => {
    if (order.subTotal > 0) {
      navigate("/payment-choice");
      setOpenShoppingCart(false);
    }
  }, [navigate]);

  return (
    <div className="fixed z-50 top-0 left-0 overflow-hidden h-screen w-screen">
      <LockBodyScroll />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        onClick={() => setOpenShoppingCart(false)}
        className="w-full h-full absolute top-0 left-0 bg-[#00152480]"
      ></motion.div>
      <motion.div
        className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 rounded-3xl bg-white max-w-[34.75rem] w-full"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="border-b border-solid border-color-grey-300 pb-4 flex justify-between gap-2">
          <h2 className="my-0 font-ivy-mode font-bold text-[2rem] leading-[3rem]">
            Košík
          </h2>
          <button
            onClick={() => setOpenShoppingCart(false)}
            className="cursor-pointer outline-none bg-white h-fit"
          >
            <img
              className="w-6 h-6"
              src="/close-sign.svg"
              alt="Zavřít nákupní seznam"
            />
          </button>
        </div>

        <div className="mt-4 flex flex-col gap-2 max-h-[18.75rem] overflow-auto pr-2">
          {order &&
            order.basket &&
            order.basket.map((basketItem, index) => (
              <ProductCard
                key={index}
                unit={basketItem}
                onRemoveOrder={() => removeFromBasket(basketItem)}
                onChangeUnitsCount={(value) =>
                  changeUnitCount(basketItem, value)
                }
                closeShoppingCart={() => setOpenShoppingCart(false)}
              />
            ))}
        </div>
        {!order || !order.basket || !order.basket.length ? (
          <div className="mb-5">
            <p className="my-0 font-graphie font-semibold text-base leading-6 text-color-black">
              Zatím v košíku nemáš žádné produkty.
            </p>
          </div>
        ) : (
          <></>
        )}

        {order && order.basket && order.basket.length ? (
          <div className="flex justify-between mt-4 gap-4">
            <div className="font-graphie font-semibold font-base leading-5 text-color-black">
              Celkem:
            </div>
            <div className="font-graphie font-bold text-xl leading-6 text-color-black">
              {formatPrice(order.subTotal || 0)} Kč
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="flex gap-12 justify-between mt-4">
          <button
            onClick={() => setOpenShoppingCart(false)}
            className="max-w-[230px] w-full cursor-pointer [border:none] py-2 rounded-full overflow-hidden flex flex-row items-center justify-center bg-color-beige hover:bg-blanchedalmond"
          >
            <b className="relative text-[1rem] font-graphie text-center">
              Pokračovat v nákupu
            </b>
          </button>
          {order && order.basket && order.basket.length ? (
            <button
              onClick={onCompleteCart}
              className="w-full  cursor-pointer [border:none] py-2 bg-color-black rounded-full overflow-hidden flex flex-row items-center justify-center"
            >
              <b className="relative text-[1rem] font-graphie text-color-white text-center">
                Objednat
              </b>
            </button>
          ) : (
            <></>
          )}
        </div>
      </motion.div>
    </div>
  );
};

const ProductCard = ({
  unit,
  onRemoveOrder,
  onChangeUnitsCount,
  closeShoppingCart,
}) => {
  const selectedSizeObj = useMemo(() => {
    return unit.sizes.find((x) => x.value === unit.selectedSize);
  }, [unit, unit.selectedSize]);

  return (
    <div className="relative flex flex-row gap-4 pb-4 border-b border-solid border-color-grey-300">
      <button
        className="outline-none bg-white absolute top-0 right-0 cursor-pointer"
        onClick={onRemoveOrder}
      >
        <img src="/remove.svg" alt="" />
      </button>
      <div className="rounded-full w-12 h-12 overflow-hidden shrink-0 flex flex-row items-start justify-start duration-150">
        {unit.id ? (
          <Link
            to={`/product/${unit.slug}`}
            onClick={closeShoppingCart}
            className="self-stretch flex-1 relative bg-darkslategray overflow-hidden"
          >
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src={unit.image}
            />
          </Link>
        ) : (
          <div className="self-stretch flex-1 relative bg-darkslategray overflow-hidden">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src={unit.image}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-0.5 w-full pr-2">
        <div className="mx-0 font-graphie font-semibold text-base leading-5 text-color-black">
          {unit.name}
        </div>
        <div className="font-graphie text-xs leading-[0.875rem] text-color-grey-600">
          Velikost <span className="pl-1">{unit.selectedSize} ml</span>
        </div>
        <div className="flex gap-2 mt-2 justify-between items-center">
          <div className="flex gap-2 ">
            <CounterInput
              onIncrement={() => onChangeUnitsCount(1)}
              onDecrement={() => onChangeUnitsCount(-1)}
              value={unit.units}
            />
            <div className="font-graphie text-base leading-10 text-color-black">
              {formatPrice(unit.price * unit.units)} Kč
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopCart;
