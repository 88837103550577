import ReactPlayer from "react-player/lazy";

const ProductGallery = ({ productInfo }) => {
  return (
    <section className="flex gap-4 justify-center mb-8 sm2:flex-col">
      {productInfo.video && productInfo.video.length ? (
        <ProductVideo {...productInfo.video[0]} />
      ) : (
        <MainColorImage src={productInfo.image} name={productInfo.name} />
      )}
      <div className="grid grid-rows-2 md:gap-4">
        {productInfo &&
          productInfo.gallery &&
          productInfo.gallery.map((image, idx) => (
            <ProductImage src={image} key={idx} />
          ))}
      </div>
    </section>
  );
};

const ProductImage = ({ src }) => {
  return (
    <div className="max-w-[16.5rem] max-h-[16.5rem] overflow-hidden rounded-2xl md:max-w-full">
      <img className="w-full h-full object-cover" alt="" src={src} />
    </div>
  );
};

const ProductVideo = ({ type, url, filename }) => {
  return (
    <div className="flex flex-col gap-2 max-w-[484px] w-full h-[544px] sm2:max-w-full">
      <div className="w-full h-[calc(100%-20px)] bg-color-black rounded-2xl overflow-hidden">
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          muted
          playing
          loop
          controls
        />
      </div>
      <a
        href="https://www.instagram.com/hairathomeapp/"
        reffe
        target="_blank"
        className="font-graphie font-normal text-base leading-[1.2rem] text-color-grey-600 w-fit"
      >
        Zdroj: Instagram Hair@Home
      </a>
    </div>
  );
};

const MainColorImage = ({ src, name }) => {
  return (
    <div className="max-w-[484px] w-full h-[544px] sm2:max-w-full sm2:h-[16.5rem] rounded-2xl overflow-hidden">
      <img className="w-full h-full object-cover" alt={name} src={src} />
    </div>
  );
};

export default ProductGallery;
