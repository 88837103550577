const PaymentCard = ({ name, iconSrc, onSelectPaymentMethod }) => {
  return (
    <button onClick={onSelectPaymentMethod} className="bg-white cursor-pointer w-full h-[11.688rem] rounded-3xl border border-solid border-color-grey-200 flex flex-col gap-6 justify-center items-center transition-all hover:shadow active:shadow-md">
      <img src={iconSrc} alt={name} />
      <p className="py-0 my-0 font-graphie font-semibold text-base leading-5 text-color-black">
        {name}
      </p>
    </button>
  );
};

export default PaymentCard;
