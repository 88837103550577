const isFallDiscountEligible = (order) => {
  /*let colors = 0;
    let masks = 0;
  
    order.basket.forEach((item) => {
      if (item.productType === "Masky") {
        masks += item.units;
      } else if (
        item.productType === "Krycí barvy" ||
        item.productType === "Odstíny"
      ) {
        colors += item.units;
      }
    });
  
    return masks >= 2 && colors > 0;*/

  return false;
};

export default isFallDiscountEligible;
