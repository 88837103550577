import styles from "./markdown-elements.module.css";
import Markdown from "react-markdown";

const ProductDetailsParser = ({ children }) => {
  const withParsedImages = children
    .replaceAll("!\\[", "![")
    .replaceAll("]\\(", "!](");
  return (
    <section>
      <h2 className="my-0 mb-4 font-ivy-mode font-bold text-xl leading-[1.875rem] text-color-black md:text-base md:leading-6">
        Popis :
      </h2>
      <Markdown
        className={styles.markdown}
        components={{
          a: (props) => <a target="_blank" {...props} />,
          img: (props) => {
            return <img src={props.src} alt={props.alt || ""} className="w-full h-auto mt-2" />;
          },
        }}
      >
        {withParsedImages}
      </Markdown>
    </section>
  );
};

export default ProductDetailsParser;
