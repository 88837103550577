import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ColorList from "../components/ColorList";
import { OrderContext } from "../components/providers/OrderProvider";

const Colors = () => {
  const { order, setOrder } = useContext(OrderContext);

  const navigate = useNavigate();

  const hasBasketProducts = order.subTotal > 0;
  const [isLoading, setIsLoading] = useState(true);

  const [colorList, setColorList] = useState(() => {
    var Airtable = require("airtable");
    var base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_SECRET_API_TOKEN_READ,
    }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

    const list = [];
    let unitPrice = 0;
    let category = "";
    let sizes = [];
    base("Produkty")
      .select({
        maxRecords: 100,
        view: "Krycí barvy",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            unitPrice = record.get("Cena");
            category = record.get("Kategorie");
            const minSize = record.get("Velikost (ml)");
            if (minSize) {
              sizes = [
                {
                  value: minSize,
                  price_multiplicator: 1,
                  price: unitPrice,
                },
                {
                  value: minSize * 2,
                  price_multiplicator: 2,
                  price: unitPrice * 2,
                },
              ];
            }
            list.push({
              id: record.id,
              name: record.get("Název"),
              price: unitPrice,
              productType: category,
              image: record.get("Fotka")[0]["thumbnails"]["large"]["url"],
              sizes: sizes,
              slug: record.get("Slug"),
            });
          });

          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
          // Refresh prices
          let newBasket = order.basket;
          let newSubTotal = 0;
          order.basket.forEach((b, i) => {
            if (
              b.productType === "Krycí barvy" ||
              b.productType === "Odstíny"
            ) {
              const selectedSize = sizes.find(
                (size) => size.value === b.selectedSize
              );
              if (selectedSize) {
                newSubTotal +=
                  unitPrice * selectedSize.price_multiplicator * b.units;
                newBasket[i].price =
                  unitPrice * selectedSize.price_multiplicator;
              } else {
                newSubTotal += unitPrice * b.units;
                newBasket[i].price = unitPrice;
              }
            } else {
              newSubTotal += b.price * b.units;
            }
            const productIdx = list.findIndex((x) => x.slug === b.slug);
            if (productIdx > -1) {
              newBasket[i].image = list[productIdx].image;
            }
          });

          setOrder({ ...order, basket: newBasket, subTotal: newSubTotal });
          localStorage.setItem(
            "order",
            JSON.stringify({
              ...order,
              basket: newBasket,
              subTotal: newSubTotal,
            })
          );

          setColorList(list);
          setIsLoading(false);
        }
      );
  });

  const onButton1Click = useCallback(() => {
    navigate("/delivery-choice");
  }, [navigate]);

  return (
    <>
      {isLoading && (
        <div className="min-h-[20rem] w-full flex items-center justify-center">
          <span className="w-[3rem] h-[3rem] border-solid rounded-full border-[0.25rem] border-color-black border-r-transparent animate-spin"></span>
        </div>
      )}
      {!isLoading && colorList && (
        <>
          <ColorList colorList={colorList} />
          {hasBasketProducts && (
            <button
              className="w-fit mx-auto hover:bg-gray cursor-pointer [border:none] py-[1.19rem] px-[2.5rem] bg-color-black rounded-full overflow-hidden flex flex-row items-center justify-center"
              onClick={onButton1Click}
            >
              <b className="relative text-[1rem] font-graphie text-color-white text-center">
                Pokračovat
              </b>
            </button>
          )}
        </>
      )}
    </>
  );
};

export default Colors;
