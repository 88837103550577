import { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import CategoriesPage from "./pages/Categories";
import ShoppingCart from "./components/cart/ShoppingCart";
import OrderProvider from "./components/providers/OrderProvider";
import ColoringType from "./pages/ColoringType";
import DeliveryChoice from "./pages/DeliveryChoice";
import Success from "./pages/Success";
import ZasilkovnaHome from "./pages/ZasilkovnaHome";
import ZasilkovnaPickup from "./pages/ZasilkovnaPickup";
import Colors from "./pages/Colors";
import Tones from "./pages/Tones";
import Masks from "./pages/Masks";
import ProductDetails from "./pages/ProductDetails";
import PaymentChoice from "./pages/PaymentChoice";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  // Navigate user to the right step based on their progress
  /*useEffect(() => {
    if (pathname === "/order-complete" && order.status === "filling") {
      navigate(pathname);
      return;
    }

    if (order.status === "filling") {
      if (order.type === "regrowth" || order.type === "lengths") {
        if (order.basket.length > 0) {
          if (order.shipping.method !== "") {
            navigate("/" + order.shipping.method);
            return;
          } else {
            navigate("/delivery-choice");
            return;
          }
        } else {
          navigate("/colors");
          return;
        }
      }
    }

    navigate("/");
    return;
  }, []);*/

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "Hair@Home Shop";
    let metaDescription =
      "Nakupuj barvy Hair@Home rychle a bez nutnosti registrace, přímo z tvého prohlížeče.";

    switch (pathname) {
      case "/":
        title = "Co budeš barvit?" + " | " + title;
        break;
      case "/zasilkovna-home":
        title = "Zásilkovna domů" + " | " + title;
        break;
      case "/zasilkovna-pickup":
        title = "Zásilkovna výdejna" + " | " + title;
        break;
      case "/tones":
        title = "Vyber odstíny" + " | " + title;
        break;
      case "/order-complete":
        title = "Objednávka dokončena" + " | " + title;
        break;
      case "/delivery-choice":
        title = "Zvol doručovací metodu" + " | " + title;
        break;
      case "/colors":
        title = "Vyber barvy" + " | " + title;
        break;
      case "/masks":
        title = "Vyber masku" + " | " + title;
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <OrderProvider>
      <Routes>
        <Route path="/" element={<ColoringType step={1} />} />
        <Route
          path="/colors"
          element={
            <CategoriesPage>
              <Colors />
            </CategoriesPage>
          }
        />
        <Route
          path="/tones"
          element={
            <CategoriesPage>
              <Tones />
            </CategoriesPage>
          }
        />
        <Route
          path="/masks"
          element={
            <CategoriesPage>
              <Masks />
            </CategoriesPage>
          }
        />
        <Route path="/payment-choice" element={<PaymentChoice step={2} />} />
        <Route path="/zasilkovna-home" element={<ZasilkovnaHome step={3} />} />
        <Route
          path="/zasilkovna-pickup"
          element={<ZasilkovnaPickup step={3} />}
        />
        <Route path="/delivery-choice" element={<DeliveryChoice step={3} />} />
        <Route path="/order-complete" element={<Success step={4} />} />
        <Route path="/product/:id" element={<ProductDetails />} />
      </Routes>
      <ShoppingCart />
    </OrderProvider>
  );
}
export default App;
