import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const formatForDisplay = (number) => {
  return parseFloat(Math.max(number, 0)).toFixed(0).split("").reverse();
};

const CounterInput = ({ onIncrement, onDecrement, value }) => {
  return (
    <div className="flex flex-row items-center justify-start">
      <button
        className="cursor-pointer [border:none] p-[0.5rem] bg-[transparent] flex flex-row items-start justify-start"
        onClick={onDecrement}
      >
        <img
          className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0"
          alt=""
          src="/minus.svg"
        />
      </button>
      <AnimatedCounter value={value} fontSize="48px" />
      <button
        className="cursor-pointer [border:none] p-[0.5rem] bg-[transparent] flex flex-row items-start justify-start"
        onClick={onIncrement}
      >
        <img
          className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0"
          alt=""
          src="/plus.svg"
        />
      </button>
    </div>
  );
};

const AnimatedCounter = ({ value = 0 }) => {
  const numArray = formatForDisplay(value);

  return (
    <motion.div
      layout
      className="h-auto flex flex-row-reverse overflow-hidden relative"
    >
      {numArray.map((number, index) => (
        <NumberColumn key={index} digit={number} />
      ))}
    </motion.div>
  );
};

const NumberColumn = ({ digit }) => {
  const [position, setPosition] = useState(0);
  const columnContainer = useRef();

  const setColumnToNumber = (number) => {
    setPosition(columnContainer.current.clientHeight * parseInt(number, 10));
  };
  useEffect(() => setColumnToNumber(digit), [digit]);

  return (
    <div
      className="relative h-auto font-graphie text-base leading-6 text-color-black"
      ref={columnContainer}
    >
      <motion.div
        initial={{ x: 0, y: position }}
        animate={{ x: 0, y: position }}
        className={`absolute h-[1000%] bottom-0`}
      >
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => (
          <div
            key={num}
            className="h-[10%] font-graphie text-base leading-6 text-color-black"
          >
            <span>{num}</span>
          </div>
        ))}
      </motion.div>
      <span className="invisible">0</span>
    </div>
  );
};

export default CounterInput;
