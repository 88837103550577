import { useMemo } from "react";

const ProductSizeSelector = ({
  sizes,
  selectedSize,
  onChangeSize,
  containerClassName,
  buttonClassName,
}) => {
  const isMultipleSizes = useMemo(() => {
    return sizes && sizes.length > 1;
  }, [sizes]);

  return (
    sizes && (
      <div
        className={`grid ${isMultipleSizes ? "grid-cols-2 gap-1" : ""}
      ${containerClassName || ""}`}
      >
        {sizes.map((size, idx) => (
          <button
            key={idx}
            className={`cursor-pointer py-1 font-graphie font-normal text-[0.625rem] min-w-[3.125rem] text-color-grey-600 bg-transparent outline-none 
            border border-solid border-transparent
            ${
              isMultipleSizes &&
              selectedSize.value === size.value &&
              "!border-color-green rounded text-color-black !bg-white"
            }
            ${!onChangeSize ? "pointer-events-none cursor-auto" : ""}
            ${buttonClassName || ""}
            `}
            onClick={() => (onChangeSize ? onChangeSize(size) : null)}
          >
            {size.label ? size.label : `${size.value} ml`}
          </button>
        ))}
      </div>
    )
  );
};

export default ProductSizeSelector;
