const ShoppingCartWithCounter = ({ productsCount }) => {
  return (
    <div className="relative">
      <img
        className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 md:w-[1.25rem] md:h-[1.25rem] sm:w-[1.25rem] sm:h-[1.25rem]"
        alt=""
        src="/lucideshoppingcart1.svg"
      />
      {productsCount && productsCount > 0 ? (
        <div
          className={`absolute top-0 right-0 flex rounded-full items-center justify-center z-1 bg-color-orange transition-all font-graphie font-semibold text-[0.625rem] text-white align-top ${
            productsCount < 10
              ? "w-[0.875rem] h-[0.875rem] translate-x-2 -translate-y-1"
              : "w-[1.125rem] h-[1.125rem]  translate-x-2.5 -translate-y-1.5"
          }`}
        >
          {productsCount}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ShoppingCartWithCounter;
