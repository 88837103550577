const Footer = () => {
  return (
    <footer className="w-full border-t border-solid border-color-grey-300 pt-10 pb-[4.5rem] sm2:pb-12">
      <div className="flex gap-x-10 gap-y-8 items-center max-w-[52.25rem] mx-auto px-6 flex-wrap">
        <img src="/logo-small.svg" alt="Hair@Home" />
        <div className="flex gap-x-10 sm:gap-x-6 gap-y-4 items-center flex-wrap">
          <FooterLink href="https://hairathome.cz/#vyhody">Výhody</FooterLink>
          <FooterLink href="https://hairathome.cz/#jak-to-funguje">
            Jak to fuguje?
          </FooterLink>
          <FooterLink href="https://hairathome.cz/#caste-dotazy">
            Časté dotazy
          </FooterLink>
          <FooterLink href="https://hairathome.cz/obchodni-podminky.html">
            Obchodní podmínky
          </FooterLink>
          <FooterLink href="https://hairathome.cz/ochrana-osobnich-udaju.html">
            Ochrana osobních údajů
          </FooterLink>
        </div>
      </div>
    </footer>
  );
};

const FooterLink = ({ children, href }) => {
  return (
    <a
      href={href}
      className="font-graphie font-normal text-base leading-6 text-color-grey-600 no-underline transition-colors hover:text-black"
      target="_blank"
    >
      {children}
    </a>
  );
};

export default Footer;
