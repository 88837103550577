import React, { useState, useCallback } from "react";
import timeDifference from "../../utils/functions/timeDifference";
import isFallDiscountEligible from "../../utils/functions/isFallDiscountEligible";
import { isValidOrderObj } from "../../utils/functions/isValidOrderObj";

export const OrderContext = React.createContext();

const OrderProvider = ({ children }) => {
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const [useMaxSize, setUseMaxSize] = useState(false);

  const [order, setOrder] = useState(() => {
    // Restore order from localStorage or make a new order
    const template = {
      modified: new Date(),
      basket: [],
      customer: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        street: "",
        postalCode: "",
        city: "",
        country: "cz",
        heurekaConsent: true,
      },
      shipping: {
        initialPrice: 0,
        method: "",
        price: 0,
        zasilkovnaPoint: {},
      },
      //stripe or bankTransfer
      paymentMethod: "",
      status: "",
      subTotal: 0,
      total: 0,
      type: "",
      orderComplete: {
        redirectUrl: "",
        h1: "",
        h2: "",
        invoice: "",
      },
    };
    const localStorageTemplate = JSON.parse(localStorage.getItem("order"));
    const isValidInitialObject = isValidOrderObj(localStorageTemplate);
    const init = isValidInitialObject ? localStorageTemplate : template;

    // Invalidate expired order
    if (
      !init.modified ||
      timeDifference(new Date(init.modified), new Date()) > 5
    ) {
      localStorage.setItem("order", JSON.stringify(template));
      return template;
    }

    localStorage.setItem("order", JSON.stringify(init));
    return init;
  });

  const modifyBasketItem = (
    id,
    name,
    productType,
    price,
    image,
    direction,
    selectedSize,
    sizes,
    slug
  ) => {
    let basket = order.basket || [];
    let newSubTotal = 0;

    // Check if basket has product
    const index = basket.findIndex(
      (item) => item.name === name && item.selectedSize === selectedSize
    );

    if (index >= 0) {
      // Item in basket
      const newUnits = basket[index].units + direction * 1;
      if (newUnits > 0 && newUnits < 100) {
        // Units within limits
        basket[index].id = id;
        basket[index].units = newUnits;
        basket[index].price = price;
        basket[index].productType = productType;
        basket[index].image = image;
        basket[index].selectedSize = selectedSize;
        basket[index].sizes = sizes;
        basket[index].slug = slug;
      }
      if (newUnits == 0) {
        // Removed last unit, delete from basket
        basket.splice(index, 1);
      }
    } else if (direction > 0) {
      // Item not in basket, adding
      basket.push({
        id: id,
        name: name,
        units: direction * 1,
        price: price,
        productType: productType,
        image: image,
        selectedSize: selectedSize,
        sizes: sizes,
        slug: slug,
      });
    }

    // Check if user is eligible for a fall campaign discount
    if (isFallDiscountEligible(order)) {
      // Add gift to the cart
      getGiftData().then((res) => {
        if (res) {
          let found = false;

          basket.forEach((item) => {
            if (item.productType === "Dárek") {
              found = true;
            }
          });

          if (found === false) {
            basket.push(res);
          }

          // Calculate new subtotal
          basket.forEach((b) => {
            newSubTotal += b.price * b.units;
          });

          localStorage.setItem(
            "order",
            JSON.stringify({
              ...order,
              modified: new Date(),
              subTotal: newSubTotal,
              basket,
            })
          );

          setOrder((order) => ({
            ...order,
            modified: new Date(),
            subTotal: newSubTotal,
            basket,
          }));
        }
      });
    } else {
      // Remove gift from the cart
      let index = -1;

      basket.forEach((item, i) => {
        if (item.productType === "Dárek") {
          index = i;
        }
      });

      if (index > -1) {
        basket.splice(index, 1);
      }

      // Calculate new subtotal
      basket.forEach((b) => {
        newSubTotal += b.price * b.units;
      });

      localStorage.setItem(
        "order",
        JSON.stringify({ ...order, subTotal: newSubTotal, basket })
      );

      setOrder((order) => ({ ...order, subTotal: newSubTotal, basket }));
    }
  };

  const clearBasket = useCallback(() => {
    let basket = order.basket || [];
    let newSubTotal = 0;
    basket.splice(0, basket.length);
    // Check if user is eligible for a fall campaign discount
    if (isFallDiscountEligible(order)) {
      // Add gift to the cart
      getGiftData().then((res) => {
        if (res) {
          let found = false;

          basket.forEach((item) => {
            if (item.productType === "Dárek") {
              found = true;
            }
          });

          if (found === false) {
            basket.push(res);
          }

          // Calculate new subtotal
          basket.forEach((b) => {
            newSubTotal += b.price * b.units;
          });

          localStorage.setItem(
            "order",
            JSON.stringify({
              ...order,
              modified: new Date(),
              subTotal: newSubTotal,
              basket,
            })
          );

          setOrder((order) => ({
            ...order,
            modified: new Date(),
            subTotal: newSubTotal,
            basket,
          }));
        }
      });
    } else {
      // Remove gift from the cart
      let index = -1;

      basket.forEach((item, i) => {
        if (item.productType === "Dárek") {
          index = i;
        }
      });

      if (index > -1) {
        basket.splice(index, 1);
      }

      // Calculate new subtotal
      basket.forEach((b) => {
        newSubTotal += b.price * b.units;
      });

      localStorage.setItem(
        "order",
        JSON.stringify({ ...order, subTotal: newSubTotal, basket })
      );

      setOrder((order) => ({ ...order, subTotal: newSubTotal, basket }));
    }
  });

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        useMaxSize,
        setUseMaxSize,
        modifyBasketItem,
        openShoppingCart,
        setOpenShoppingCart,
        clearBasket,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
