import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavigateBackButton from "../components/product-details/NavigateBackButton";
import ProductDetailsParser from "../components/product-details/ProductDetailsParser";
import ProductGallery from "../components/product-details/ProductGallery";
import ProductIngridients from "../components/product-details/ProductIngridients";
import ProductOrder from "../components/product-details/ProductOrder";
import ProductTitle from "../components/product-details/ProductTitle";
import Footer from "../components/Footer";
import ProductDetailsLayout from "../components/product-details/DetailsLayout";

const ProductDetails = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [productInfo, setProductInfo] = useState();
  const [relatedProducts, setRelatedProducts] = useState([]);

  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_SECRET_API_TOKEN_READ,
  }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

  useEffect(() => {
    setIsLoading(true);
    base("Produkty")
      .select({
        filterByFormula: `{fld2f4nlirVgR60WI}='${id}'`,
        maxRecords: 100,
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            try {
              setIsLoading(false);
              if (!record._rawJson && record.fields) {
                return;
              }
              let product = {
                id: record.id,
                price: record.fields["Cena"],
                formattedPrice: record.fields["Cena (formátovaná)"],
                details: record.fields["Detaily"],
                image: record.fields["Fotka"][0]["thumbnails"]["large"]["url"],
                gallery: record.fields["Galerie"].map(
                  (gallery) => gallery["thumbnails"]["large"]["url"]
                ),
                category: record.fields["Kategorie"],
                shortDescription: record.fields["Krátký popis"],
                name: record.fields["Název"],
                formattedName: record.fields["Název (formátovaný)"],
                ingridients: record.fields["Složení"],
                sizeInMl: record.fields["Velikost (ml)"],
                formattedSizeInMl: record.fields["Velikost (ml) - formátovaná"],
                video: record.fields["Video"],
                sizes: [],
                slug: record.fields["Slug"],
              };
              let sizes = [];
              if (product.sizeInMl) {
                if (product.category !== "Masky") {
                  sizes = [
                    {
                      label: (
                        <>
                          Malá{" "}
                          <span className="text-color-grey-600">
                            ({product.sizeInMl} ml)
                          </span>
                        </>
                      ),
                      value: product.sizeInMl,
                      price_multiplicator: 1,
                      price: product.price,
                    },
                    {
                      label: (
                        <>
                          Velká{" "}
                          <span className="text-color-grey-600">
                            ({product.sizeInMl * 2} ml)
                          </span>
                        </>
                      ),
                      value: product.sizeInMl * 2,
                      price_multiplicator: 2,
                      price: product.price * 2,
                    },
                  ];
                } else {
                  sizes = [
                    {
                      value: product.sizeInMl,
                      price_multiplicator: 1,
                      price: product.price,
                    },
                  ];
                }
              }
              product.sizes = sizes;
              setProductInfo(product);
            } catch (err) {
              console.log(err);
            }
          });
          fetchNextPage();
        },
        function done(error) {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    if (!productInfo || productInfo.category === "Masky") {
      return;
    }
    base("Produkty")
      .select({
        maxRecords: 100,
        view: productInfo.category,
        fields: ["Fotka", "Slug"],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          const items = [];
          records.forEach((item) => {
            items.push({
              id: item.id,
              slug: item.fields["Slug"],
              image: item.fields["Fotka"][0]["thumbnails"]["large"]["url"],
            });
          });
          setRelatedProducts((prev) => [...prev, ...items]);
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, [productInfo]);

  return (
    <ProductDetailsLayout productInfo={productInfo}>
      <div className="w-full flex flex-col justify-between min-h-screen">
        <main id="details-main-content" className="relative max-w-[47.75rem] px-6 mx-auto pt-10 pb-20 md:pb-44">
          {isLoading && (
            <div className="min-h-[20rem] w-full flex items-center justify-center">
              <span className="w-[3rem] h-[3rem] border-solid rounded-full border-[0.25rem] border-color-black border-r-transparent animate-spin"></span>
            </div>
          )}
          {!isLoading && productInfo && (
            <>
              <ProductTitle
                title={productInfo.name}
                description={productInfo.shortDescription}
              />
              <ProductGallery productInfo={productInfo} />

              <SeparatorLine />

              <ProductOrder
                productInfo={productInfo}
                relatedProducts={relatedProducts}
              />

              <SeparatorLine className="mb-8 md:mb-2" />

              <ProductDetailsParser>{productInfo.details}</ProductDetailsParser>

              <SeparatorLine className="mb-8 md:mb-2" />

              <ProductIngridients>{productInfo.ingridients}</ProductIngridients>
            </>
          )}
        </main>
        <Footer />
      </div>
    </ProductDetailsLayout>
  );
};

export default ProductDetails;

const SeparatorLine = ({ className }) => {
  return (
    <div className={`bg-color-grey-300 w-full h-[1px] ${className}`}></div>
  );
};
