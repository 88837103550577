const ProductIngridients = ({ children }) => {
  return (
    <section>
      <h2 className="mt-6 mb-8 font-ivy-mode font-bold text-xl leading-8 text-color-black">
        Složení
      </h2>
      <p className="my-0 font-graphie font-normal text-base leading-6 text-color-grey-600">
        {children}
      </p>
    </section>
  );
};

export default ProductIngridients;
