import useLockBodyScroll from "../../utils/hooks/useLockBodyScroll";
import useWindowSize from "../../utils/hooks/useWindowSize";

import { AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { OrderContext } from "../../components/providers/OrderProvider";
import DesktopCart from "./DesktopCart";
import MobileCart from "./MobileCart";

const ShoppingCart = () => {
  const { openShoppingCart, modifyBasketItem, clearBasket } =
    useContext(OrderContext);
  const { width } = useWindowSize();

  const changeUnitCount = (basketItem, count) => {
    modifyBasketItem(
      basketItem.id,
      basketItem.name,
      basketItem.productType,
      basketItem.price,
      basketItem.image,
      count,
      basketItem.selectedSize,
      basketItem.sizes,
      basketItem.slug
    );
  };

  const removeFromBasket = (basketItem) => {
    modifyBasketItem(
      basketItem.id,
      basketItem.name,
      basketItem.productType,
      basketItem.price,
      basketItem.image,
      -basketItem.units,
      basketItem.selectedSize,
      basketItem.sizes,
      basketItem.slug
    );
  };

  const removeAllUnits = () => {
    clearBasket();
  };

  return (
    <AnimatePresence initial={false} mode="wait">
      {!openShoppingCart ? (
        <></>
      ) : width > 768 ? (
        <DesktopCart
          key="desktop cart"
          changeUnitCount={changeUnitCount}
          removeFromBasket={removeFromBasket}
        />
      ) : (
        <MobileCart
          key="mobile cart"
          changeUnitCount={changeUnitCount}
          removeFromBasket={removeFromBasket}
          removeAllUnits={removeAllUnits}
        />
      )}
    </AnimatePresence>
  );
};

export const LockBodyScroll = () => {
  useLockBodyScroll();
  return <></>;
};

export default ShoppingCart;
