import { useCallback, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useTimer from "../utils/hooks/useTimer";
import { OrderContext } from "./providers/OrderProvider";
import CounterInput from "./shared/CounterInput";
import ProductSizeSelector from "./shared/ProductSizeSelector";

async function getGiftData() {
  var Airtable = require("airtable");
  var base = new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_SECRET_API_TOKEN_READ,
  }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

  const record = await base("Produkty").find("recHeuyP7FKwO9utQ");

  const item = {
    name: record.get("Název"),
    units: 1,
    price: record.get("Cena"),
    productType: "Dárek",
    image: record.get("Fotka")[0]["thumbnails"]["large"]["url"],
  };

  return item;
}

const ColorSwatch = ({
  name,
  price,
  productType,
  image,
  id,
  sizes,
  slug,
  defaultSize,
}) => {
  const [basketItem, setBasketItem] = useState({
    units: 0,
    selectedSize: defaultSize || sizes[0],
  });

  const { setOpenShoppingCart, modifyBasketItem } = useContext(OrderContext);

  const productPrice = useMemo(() => {
    return price * basketItem.selectedSize.price_multiplicator;
  }, [basketItem.selectedSize]);

  const onPlusColorToBasketClick = useCallback(() => {
    setBasketItem((prev) => ({ ...prev, units: prev.units + 1 }));
  }, [basketItem.selectedSize]);

  const onMinusColorToBasketClick = useCallback(() => {
    if (basketItem.units === 0) {
      return;
    }
    setBasketItem((prev) => ({ ...prev, units: prev.units - 1 }));
  }, [basketItem.selectedSize, basketItem.units]);

  const animateSelectedUnits = useTimer(basketItem.units, 150);

  const onChangeProductSize = (productSize) => {
    setBasketItem((prev) => ({ ...prev, selectedSize: productSize }));
  };

  const addProductToCart = () => {
    if (basketItem.units) {
      modifyBasketItem(
        id,
        name,
        productType,
        productPrice,
        image,
        basketItem.units,
        basketItem.selectedSize.value,
        sizes,
        slug
      );
      setBasketItem({
        units: 0,
        selectedSize: sizes[0],
      });
    }

    setOpenShoppingCart(true);
  };

  return (
    <li
      className={`${
        basketItem.units > 0
          ? "bg-color-beige hover:bg-blanchedalmond rounded-b-none"
          : "hover:bg-color-grey-100"
      } relative group justify-between self-stretch rounded-3xl flex flex-col pt-[1.5rem] px-[1rem] pb-2 items-center gap-[0.5rem] text-center text-[1rem] text-color-black font-graphie`}
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-[1rem]">
        <div className="flex flex-row items-center justify-center">
          <Link to={`/product/${slug}`}>
            <div
              className={`rounded-full w-[5.5rem] h-[5.5rem] overflow-hidden shrink-0 flex flex-row items-start justify-start md:w-[5.5rem] sm:w-[4.5rem] sm:h-[4.5rem] duration-150 ${
                animateSelectedUnits ? "scale-110" : ""
              }`}
            >
              <div className="self-stretch flex-1 relative bg-darkslategray overflow-hidden">
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src={image}
                />
              </div>
            </div>
          </Link>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[0.25rem]">
          <div className="self-stretch relative leading-[120%] font-semibold">
            {name}
          </div>
        </div>
      </div>
      <div
        className={`flex gap-2 justify-center items-center ${
          sizes && sizes.length > 1 ? "flex-col" : "flex-row"
        }
        ${
          basketItem.units === 0
            ? "opacity-0 group-hover:opacity-100 transition-all"
            : ""
        }`}
      >
        <ProductSizeSelector
          sizes={sizes}
          selectedSize={basketItem.selectedSize}
          onChangeSize={onChangeProductSize}
        />
        {basketItem.units > 0 && (
          <div
            className={`${
              basketItem.units > 0 ? "" : "invisible"
            } text-sm font-semibold`}
          >
            {productPrice * basketItem.units} Kč
          </div>
        )}
      </div>
      <div
        className={`${
          basketItem.units === 0
            ? "opacity-0 group-hover:opacity-100 transition-all"
            : ""
        }`}
      >
        <CounterInput
          onIncrement={() => onPlusColorToBasketClick()}
          onDecrement={() => onMinusColorToBasketClick()}
          value={basketItem.units}
        />
      </div>
      {basketItem.units > 0 && (
        <div className="absolute z-10 rounded-b-3xl top-full w-full bg-color-beige group-hover:bg-blanchedalmond">
          <button
            className="mx-auto mt-0 mb-4 w-[calc(100%-2rem)] cursor-pointer [border:none] py-2 bg-color-black rounded-full overflow-hidden flex flex-row items-center justify-center"
            onClick={addProductToCart}
          >
            <b className="relative text-[1rem] font-graphie text-color-white text-center">
              do košíku
            </b>
          </button>
        </div>
      )}
    </li>
  );
};

export default ColorSwatch;
