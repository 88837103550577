const ArrowLeft = ({ className, strokeWidth }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      stoke="#001524"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M31.6667 20H8.33335M8.33335 20L20 8.33337M8.33335 20L20 31.6667"
        strokeWidth={strokeWidth ? strokeWidth : "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
