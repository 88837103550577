import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { OrderContext } from "../components/providers/OrderProvider";

const Success = ({ step }) => {
  const { order, setOrder } = useContext(OrderContext);
  const [paymentResponse, setPaymentResponse] = useState();

  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    window.open("https://hairathome.cz");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    window.open("https://hairathome.cz");
  }, []);

  useEffect(() => {
    setPaymentResponse(order.orderComplete);
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        status: "complete",
      })
    );

    setOrder((order) => ({
      ...order,
      status: "complete",
    }));

    localStorage.removeItem("order");
  }, []);

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-start">
      <Navbar onLogoClick={onLogoClick} step={step} />
      <main className="self-stretch flex flex-col pt-[2rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[2.5rem] text-center text-[3rem] text-color-black font-ivy-mode md:pt-[1.5rem] md:px-[1.5rem] md:pb-[2rem] md:box-border sm:pl-[1.5rem] sm:pt-[2rem] sm:box-border">
        <img
          className="relative w-[16.88rem] h-[16.88rem] md:w-[10rem] md:h-[10rem]"
          alt="Objednávka dokončena"
          src="/success.svg"
        />
        <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
          <b className="self-stretch relative leading-[150%] md:text-[1.5rem]">
            {paymentResponse && paymentResponse.h1
              ? paymentResponse.h1
              : "Objednávka zaplacena"}
          </b>
          <div className="self-stretch relative text-[1.25rem] leading-[150%] font-graphie text-dimgray md:text-[1.13rem]">
            {paymentResponse && paymentResponse.h2
              ? paymentResponse.h2
              : "Děkujeme za nákup. O stavu objednávky tě budeme průběžně informovat."}
          </div>

          {paymentResponse && paymentResponse.invoice ? (
            <a
              className="underlined cursor-pointer mt-2 bg-transparent flex items-center font-graphie text-color-black text-base leading-6 group hover:text-brown focus:text-brown focus:border-b focus:border-b-brown outline-none"
              href={paymentResponse.invoice}
              target="_blank"
            >
              Zobrazit fakturu
            </a>
          ) : (
            <></>
          )}
        </div>
        <button
          className="cursor-pointer [border:none] py-[1.19rem] px-[2.5rem] bg-color-black rounded-full overflow-hidden flex flex-row items-center justify-center"
          onClick={onButton1Click}
        >
          <b className="relative text-[1rem] font-graphie text-color-white text-center">
            Zpět Domů
          </b>
        </button>
      </main>
    </div>
  );
};

export default Success;
