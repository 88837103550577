export const isValidOrderObj = (objFromLocalStorage) => {
  try {
    if (
      !objFromLocalStorage ||
      !objFromLocalStorage.basket ||
      !objFromLocalStorage.basket.length
    ) {
      return false;
    }
    const firstItemFromBasket = objFromLocalStorage.basket[0];
    if (!firstItemFromBasket) {
      return false;
    }
    return (
      firstItemFromBasket.id &&
      firstItemFromBasket.image &&
      firstItemFromBasket.name &&
      firstItemFromBasket.price &&
      firstItemFromBasket.price &&
      firstItemFromBasket.productType &&
      firstItemFromBasket.selectedSize &&
      firstItemFromBasket.sizes &&
      firstItemFromBasket.slug &&
      firstItemFromBasket.units
    );
  } catch (err) {
    return false;
  }
};
