const ProductTitle = ({ title, description }) => {
  return (
    <section>
      <h1 className="md:text-5xl md:leading-9 md:pb-6 font-ivy-mode text-color-black font-bold text-29xl leading-[4.5rem] pb-4 m-0">
        {title}
      </h1>
      <h2 className="md:font-normal md:text-base md:leading-6 md:pb-6 font-graphie text-xl leading-[1.875rem] text-color-grey-600 pb-8 m-0">
        {description}
      </h2>
    </section>
  );
};

export default ProductTitle;
