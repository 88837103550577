import { Link, useParams } from "react-router-dom";
import { useState, useMemo, useContext, useRef, useEffect } from "react";
import { OrderContext } from "../providers/OrderProvider";
import ProductSizeSelector from "../shared/ProductSizeSelector";

const ProductOrder = ({ productInfo, relatedProducts }) => {
  const { id } = useParams();
  const orderBtnRef = useRef();
  const [selectedSize, setSelectedSize] = useState(productInfo.sizes[0]);
  const { setOpenShoppingCart, modifyBasketItem, order, setOrder } =
    useContext(OrderContext);

  const productPrice = useMemo(() => {
    return productInfo.price * selectedSize.price_multiplicator;
  }, [selectedSize]);

  const addToCart = () => {
    modifyBasketItem(
      productInfo.id,
      productInfo.name,
      productInfo.category,
      productPrice,
      productInfo.image,
      "1",
      selectedSize.value,
      productInfo.sizes.map(({ label, ...size }) => size),
      productInfo.slug
    );
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        type: "regrowth",
        status: "filling",
      })
    );

    setOrder((order) => ({
      ...order,
      type: "regrowth",
      status: "filling",
    }));
    setOpenShoppingCart(true);
  };

  useEffect(() => {
    const offset = 32;
    const handleScroll = () => {
      if (window.innerWidth > 960) {
        orderBtnRef.current.style.position = "relative";
        orderBtnRef.current.style.bottom = `0px`;
        return;
      }
      const mainContent = document.getElementById("details-main-content");
      if (!mainContent) {
        return;
      }
      const mainContentRect = mainContent.getBoundingClientRect();
      const bottomLimit =
        mainContentRect.bottom - orderBtnRef.current.offsetHeight - offset;

      if (bottomLimit <= window.innerHeight) {
        orderBtnRef.current.style.position = "absolute";
        orderBtnRef.current.style.bottom = `72px`;
      } else {
        orderBtnRef.current.style.position = "fixed";
        orderBtnRef.current.style.bottom = `${offset}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [orderBtnRef]);

  return (
    <section className="flex flex-col w-full gap-8 py-8 md:flex-col-reverse md:gap-4">
      {(productInfo.sizes && productInfo.sizes.length > 1) ||
      (relatedProducts && relatedProducts.length) ? (
        <div className="flex gap-3 justify-between md:flex-col md:gap-8">
          <div className="flex flex-col gap-4">
            <p className="my-0 py-0 font-graphie font-normal text-base text-black">
              Odstín:{" "}
              <span className="text-color-grey-600">{productInfo.name}</span>
            </p>
            <div className="flex gap-2 flex-wrap items-center">
              {relatedProducts.map((product, idx) => (
                <Link
                  key={idx}
                  reloadDocument={id !== product.slug}
                  to={`/product/${product.slug}`}
                  className={`relative rounded-full flex`}
                >
                  {id === product.slug ? (
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full w-9 h-9 border border-solid border-brown"></div>
                  ) : (
                    <></>
                  )}
                  <img
                    src={product.image}
                    alt=""
                    className="rounded-full object-cover w-8 h-8"
                  />
                </Link>
              ))}
            </div>
          </div>
          {productInfo.sizes && productInfo.sizes.length > 1 && (
            <div className="flex gap-4 flex-col w-full max-w-[17.125rem]">
              <p className="py-0 my-0">
                <span className="font-graphie font-normal text-base text-color-black">
                  Velikost balení:
                </span>{" "}
                <span className="font-graphie font-normal text-base text-color-grey-600">
                  {selectedSize.label}
                </span>
              </p>
              <ProductSizeSelector
                sizes={productInfo.sizes}
                selectedSize={selectedSize}
                onChangeSize={setSelectedSize}
                containerClassName="gap-2"
                buttonClassName="font-graphie font-normal !text-color-black text-base py-2"
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="flex justify-between items-center">
        <p className="py-0 my-0 font-graphie font-bold text-[2rem] leading text-color-black">
          {productPrice} Kč
        </p>
        <button
          ref={orderBtnRef}
          onClick={addToCart}
          className="md:left-1/2 md:-translate-x-1/2 md:py-4 md:bottom-8 md:max-w-[22.875rem] md:w-[calc(100%-20px)] w-full max-w-[17.125rem] hover:bg-gray/100 cursor-pointer [border:none] py-[1.19rem] px-[2.5rem] bg-color-black rounded-full overflow-hidden flex flex-row items-center justify-center"
        >
          <b className="relative text-[1rem] font-graphie text-color-white text-center">
            <span className="md:hidden">Do košíku</span>{" "}
            <span className="hidden md:flex">Koupit — {productPrice} Kč</span>
          </b>
        </button>
      </div>
    </section>
  );
};
export default ProductOrder;
