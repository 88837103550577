import { useState, useEffect, useRef } from "react";

const useTimer = (triggerState, timing) => {
  const [timerEnded, setTimerEnded] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!triggerState) {
      return;
    }
    setTimerEnded(true);
    const timeout = setTimeout(() => {
      setTimerEnded(false);
    }, timing);
    return () => clearTimeout(timeout);
  }, [triggerState, timing]);

  return timerEnded;
};

export default useTimer;
