import ColorSwatch from "./ColorSwatch";
import { useContext } from "react";
import { OrderContext } from "./providers/OrderProvider";

const ColorList = ({ colorList }) => {
  const { useMaxSize } = useContext(OrderContext);

  return (
    <ul className="max-w-[60rem] mx-auto w-full self-stretch overflow-visible grid flex-row items-start justify-start grid-cols-5 md:grid-cols-3 sm:grid-cols-2 text-center text-[1rem] text-color-black font-graphie pl-0 gap-[1.5rem]">
      {colorList.map((color, index) => (
        <ColorSwatch
          key={index}
          {...color}
          defaultSize={useMaxSize ? color.sizes.at(-1) : undefined}
        />
      ))}
    </ul>
  );
};

export default ColorList;
