import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const ProductCategories = ({ tabs }) => {
  let [activeTab, setActiveTab] = useState();
  const location = useLocation();

  useEffect(() => {
    setActiveTab(tabs.find((x) => x.route === location.pathname).id);
  }, [location]);

  return (
    <nav
      className="self-stretch items-stretch w-full rounded-full bg-color-grey-100 overflow-hidden flex flex-row justify-start gap-2 text-center text-color-white font-ivy-mode"
      id="category-switcher"
    >
      {tabs.map((tab) => (
        <Link
          key={tab.id}
          to={tab.route}
          replace
          onClick={() => {
            setActiveTab(tab.id);
          }}
          className={`relative md:text-sm text-xl rounded-full cursor-pointer [text-decoration:none] flex-1 flex flex-row py-2 px-10 md:px-3 items-center justify-center text-color-black hover:bg-color-grey-200 sm:px-4 sm:box-border
          ${activeTab && activeTab === tab.id ? "text-color-white" : ""}`}
          style={{
            WebkitTapHighlightColor: "transparent",
          }}
        >
          {activeTab === tab.id && (
            <motion.span
              layoutId="bubble"
              className="absolute inset-0 z-0 bg-color-black "
              style={{ borderRadius: 9999 }}
              transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
            />
          )}
          <b className="flex-1 relative leading-[120%] ">{tab.tabName}</b>
        </Link>
      ))}
    </nav>
  );
};

export default ProductCategories;
